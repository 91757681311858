// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/** @public */
export interface ToStyleString {
  toStyleString(): string;
}

/** @public */
export function ToStyleString(value: unknown): string {
  if (typeof value === "object" && value !== null) {
    if (typeof (value as ToStyleString).toStyleString === "function") {
      return (value as ToStyleString).toStyleString();
    } else {
      return value.toString();
    }
  }
  return "" + value;
}
